import React, { useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  styled,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { Filter, FilterType, FetchResourcesHook } from "./types";
import IdentifierFilter from "./FilterTypes/IdentifierFilter";
import AmountFilter from "./FilterTypes/AmountFilter";
import DateRangeFilter from "./FilterTypes/DateRangeFilter";
import TextFilter from "./FilterTypes/TextFilter";
import AutoTextFilter from "./FilterTypes/AutoTextFilter";
import EnumFilter from "./FilterTypes/EnumFilter";
import TimeFilter from "./FilterTypes/TimeFilter";
import RangeFilter from "./FilterTypes/RangeFilter";

interface Props {
  item: Filter;
  fetchResourcesHook: FetchResourcesHook;
}

export const FilterItem = ({ item, fetchResourcesHook }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selection, setSelection] = useState<string[]>(
    item?.value ? item.value.split(",") : []
  );

  const openDropdown = () => {
    let selectedValues: string[] = [];
    queryParams.forEach((value, key) => {
      if (key === item.id) {
        selectedValues = value.split(",");
      }
    });
    setSelection(selectedValues);
    setShowPopup(!showPopup);
  };

  const resetParamsQuery = () => {
    const params: string[] = [];

    queryParams.forEach((value, key) => {
      if (key === item?.id) {
        params.push(key);
      }
    });
    params.forEach((key) => {
      queryParams.delete(key);
    });
    history.replace({
      search: queryParams.toString(),
    });
  };

  const addFilter = () => {
    const newSelection = selection.slice();
    if (selection.length > 1 && selection.indexOf("UNBILLED") !== -1) {
      newSelection.splice(selection.indexOf("UNBILLED"), 1);
    }
    const updated = {
      ...item,
      values: [newSelection?.join(",")],
    };

    resetParamsQuery();
    addFilterParams(updated);
  };

  const addFilterParams = (updated: Filter) => {
    updated?.values?.forEach((value) => {
      queryParams.append(updated?.id, value);
    });
    history.replace({
      search: queryParams.toString(),
    });
    setShowPopup(false);
  };

  const resetFilter = () => {
    resetParamsQuery();
    setSelection([]);
  };

  const closeFilter = () => {
    setSelection([]);
    setShowPopup(false);
  };

  const renderFilterByType = (item: Filter) => {
    let FilterComponent = null;
    if (item.name === "Call Duration") FilterComponent = TimeFilter;
    else {
      switch (item.type) {
        case FilterType.IDENTIFIER: {
          FilterComponent = IdentifierFilter;
          break;
        }
        case FilterType.RANGE: {
          FilterComponent = RangeFilter;
          break;
        }
        case FilterType.ENUM: {
          FilterComponent = EnumFilter;
          break;
        }
        case FilterType.AMOUNT: {
          FilterComponent = AmountFilter;
          break;
        }
        case FilterType.TEXT: {
          FilterComponent = TextFilter;
          break;
        }
        case FilterType.AUTO_TEXT: {
          FilterComponent = AutoTextFilter;
          break;
        }
        case FilterType.DATE_RANGE: {
          FilterComponent = DateRangeFilter;
          break;
        }
      }
    }
    if (!FilterComponent) return;
    return (
      <FilterComponent
        key={item?.id}
        item={item}
        options={item.options}
        setSelection={setSelection}
        selection={selection}
        addSelection={addFilter}
        resetSelection={resetFilter}
        close={closeFilter}
        fetchResourcesHook={fetchResourcesHook}
      />
    );
  };

  const onFilterAwayClick = (e: any) => {
    const filterBtn = e.path.find((e: any) => {
      return e.attributes?.[3]?.nodeValue === `filter-button-${item.id}`;
    });
    if (filterBtn || item.type === "RANGE") return; //@hack - select portal fires clickaway
    setShowPopup(false);
  };

  return (
    <div ref={anchorRef} data-cy={`filter-id-${item.id}`}>
      <Button
        variant="text"
        sx={getStyles.itemButton}
        onClick={openDropdown}
        data-id={`filter-button-${item.id}`}
      >
        {item.name}
        {showPopup ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Popper
        open={showPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        placement="bottom-start"
        sx={getStyles.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={5}>
              <ClickAwayListener
                onClickAway={onFilterAwayClick}
                disableReactTree
              >
                <Div>{renderFilterByType(item)}</Div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const Div = styled("div")({
  minWidth: 250,
  maxWidth: 500,
  padding: "0px",
});

const getStyles = {
  popper: {
    zIndex: 1100,
  },
  itemButton: {
    fontWeight: 600,
    fontSize: "0.95rem",
  },
};
