import React from "react";
import { Typography, Button } from "@mui/material";
import { PlaceholderImage } from "@akj-dev/shared-components";
import Main from "../layout/Main";
import header from "../images/header.jpg";

export const Home = () => {
  return (
    <Main title="Insight (Coming Soon)">
      <PlaceholderImage
        src={header}
        title="Insight Header"
        alt="Insight Header"
        width="100%"
      />
      <Typography variant="body1" gutterBottom>
        With in depth and intuitive reporting, Affinity Insight automatically
        identifies and understands trends, turning them into opportunities to
        increase sales and enhance the customer experience. Turn your data into
        your biggest asset.
      </Typography>
      <Typography variant="h3" gutterBottom>
        Key Features
      </Typography>
      <Typography variant="h6">Single Data View</Typography>
      <Typography variant="body1" gutterBottom>
        Pull data from several sources to create a single, unified data
        universe.
      </Typography>
      <Typography variant="h6">Dashboards</Typography>
      <Typography variant="body1" gutterBottom>
        Understand and visualise data trends with intuitive dashboards with
        advanced filtering and search functions to extrapolate the detail.
      </Typography>
      <Typography variant="h6">Machine Learning</Typography>
      <Typography variant="body1" gutterBottom>
        Utilising AI technology to identify trends and patterns with automated
        delivery of reports to take action.
      </Typography>
      <Button
        size="medium"
        variant="contained"
        color="primary"
        href="https://aurora.io/products/affinity-insight/"
        target="_blank"
      >
        Find out more
      </Button>
    </Main>
  );
};
